import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormHelperText, MenuItem, Select, TextField } from "@mui/material";

import { makeStyles } from "theme";
import { DEFAULT_REASON } from "../index";

const useStyles = makeStyles()(({ spacing, palette }) => ({
  info: {
    fontSize: "1rem",
    textAlign: "center",
    marginBottom: spacing(1),
    marginTop: spacing(4),

    "&:first-of-type": {
      marginTop: 0,
    },
  },
  error: {
    fontSize: "0.9rem",
    color: palette.error.main,
  },
}));

const ReasonStep = () => {
  const { classes } = useStyles();

  const { control, watch } = useFormContext();

  return (
    <div>
      <div className={classes.info}>
        Hvorfor ønsker du å avslutte medlemskapet?
      </div>

      <Controller
        control={control}
        name="reason"
        render={({ field, fieldState }) => (
          <>
            {!!fieldState.error && (
              <FormHelperText className={classes.error}>
                {fieldState.error?.message}
              </FormHelperText>
            )}

            <Select
              fullWidth
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={!!fieldState.error}
              data-testid="reason-dropdown"
            >
              <MenuItem key="none" value="none" disabled>
                <span
                  style={{
                    fontStyle: "italic",
                    color: !!fieldState.error ? "#d32f2f" : "initial",
                  }}
                >
                  Velg årsak
                </span>
              </MenuItem>

              <MenuItem key="relocation" value="relocation">
                Flytter
              </MenuItem>

              <MenuItem key="time" value="time">
                Ikke tid til å trene
              </MenuItem>

              <MenuItem key="motivation" value="motivation">
                Mistet treningslysten
              </MenuItem>

              <MenuItem key="gym-change" value="gym-change">
                Byttet treningssenter
              </MenuItem>

              <MenuItem key="requirements" value="requirements">
                Passer ikke mine behov
              </MenuItem>

              <MenuItem key="health" value="health">
                Helsemessige årsaker
              </MenuItem>

              <MenuItem key="other" value="other">
                Annet
              </MenuItem>
            </Select>
          </>
        )}
      />

      {watch("reason") !== DEFAULT_REASON && (
        <>
          <div className={classes.info}>Utfyllende kommentar</div>

          <Controller
            control={control}
            name="reasonDescription"
            render={({ field }) => (
              <TextField
                fullWidth
                multiline
                rows={3}
                value={field.value}
                onChange={field.onChange}
                data-testid="reason-description-input"
              />
            )}
          />
        </>
      )}
    </div>
  );
};

export default ReasonStep;
