import React, { Fragment, useMemo, useState } from "react";
import moment from "moment";
import { Button, darken, Grid } from "@mui/material";
import {
  AcUnit,
  ArrowDownward,
  CancelOutlined,
  CardMembershipOutlined,
} from "@mui/icons-material";

import {
  DATE_ISO_FORMAT,
  Membership as MembershipModel,
  MembershipFreeze,
  MembershipType,
} from "@tnt/common";
import { makeStyles } from "theme";
import Scene from "components/Scene";
import MembershipView from "./components/MembershipView";
import useMembershipTypes from "hooks/useMembershipTypes";
import useMembershipsOfMember from "hooks/useMembershipsOfMember";
import FreezeModal from "scenes/Memberships/components/FreezeModal";
import CancellationModal from "scenes/Memberships/components/CancellationModal";

const useStyles = makeStyles()(({ spacing }) => ({
  arrow: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "0 !important",
    marginTop: -4,

    "& > svg": {
      display: "block",
      color: "#888888",
    },
  },
  actions: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  freezeButton: {
    marginRight: spacing(2),
    color: "white",
    backgroundColor: "#3f75bd",

    "&:hover": {
      backgroundColor: darken("#3f75bd", 0.2),
    },
  },
  cancelButton: {
    marginLeft: spacing(2),
    color: "white",
    backgroundColor: "#cc5050",

    "&:hover": {
      backgroundColor: darken("#cc5050", 0.2),
    },
  },
}));

const renderArrow = (classes: Record<string, string>) => (
  <Grid className={classes.arrow} item xs={12}>
    <ArrowDownward fontSize="large" />
  </Grid>
);

const Memberships = () => {
  const { classes } = useStyles();

  const [freezeInModal, setFreezeInModal] = useState<MembershipFreeze>();
  const [membershipInFreezeModal, setMembershipInFreezeModal] =
    useState<MembershipModel>();

  const [isCancellationModalOpen, setCancellationModalOpen] = useState(false);

  const membershipTypes = useMembershipTypes();
  const [membershipsOfMember] = useMembershipsOfMember();
  const sortedMembershipsOfMember = membershipsOfMember?.sort((a, b) =>
    moment(a.start).isBefore(b.start) ? -1 : 1
  );

  const showFreezeButton = useMemo(() => {
    const active = membershipsOfMember?.find((ms) =>
      ms.getDateRange().contains(moment())
    );
    if (
      !active ||
      membershipTypes?.find((mt) => mt.id === active.typeId)
        ?.memberFreezable === false
    )
      return false;
    else return true;
  }, [membershipsOfMember, membershipTypes]);

  const activeAndUpcomingMemberships = sortedMembershipsOfMember?.filter(
    (ms) => ms.end === null || moment(ms.end).isAfter(moment())
  );

  const membershipToType:
    | Array<{ membership: MembershipModel; type: MembershipType | undefined }>
    | undefined = activeAndUpcomingMemberships?.map((ms) => {
    const type = membershipTypes?.find((mt) => mt.id === ms.typeId);
    return { membership: ms, type: type };
  });

  if (!membershipToType || membershipToType.some(({ type }) => !type))
    return null;

  const membershipWithUnfulfilledNext = membershipsOfMember?.find(
    (m) => m.nextTypeId !== null && m.nextMembershipId === null
  );

  const nextType = membershipTypes?.find(
    (mt) => mt.id === membershipWithUnfulfilledNext?.nextTypeId
  );
  const nextMembership = nextType
    ? new MembershipModel({
        start: moment(membershipWithUnfulfilledNext?.end)
          .add(1, "day")
          .format(DATE_ISO_FORMAT),
      })
    : undefined;

  const cancellableMembershipsCount =
    membershipToType?.filter(
      ({ membership, type }) =>
        type!.memberCancellable &&
        (membership.end === null ||
          (membership.nextTypeId !== null &&
            membership.nextMembershipId === null))
    ).length ?? 0;

  const handleCreateFreeze = () => {
    const membership = activeAndUpcomingMemberships![0];
    setMembershipInFreezeModal(membership);

    const latestFreezeEndDate =
      membership.freezes.length > 0
        ? moment(
            membership.freezes
              .map((f) => f.end)
              .sort((a, b) => (moment(a).isAfter(b) ? -1 : 1))[0]
          )
        : undefined;
    let date = (
      !!latestFreezeEndDate && moment(latestFreezeEndDate).isAfter(moment())
        ? moment(latestFreezeEndDate)
        : moment()
    ).add(1, "days");

    setFreezeInModal(
      new MembershipFreeze({
        start: date.format(DATE_ISO_FORMAT),
        end: date.add(1, "month").subtract(1, "day").format(DATE_ISO_FORMAT),
      })
    );
  };

  return (
    <Scene
      title="Medlemskap"
      icon={
        <CardMembershipOutlined fontSize="large" style={{ color: "#FFC107" }} />
      }
      actions={
        (showFreezeButton || cancellableMembershipsCount > 0) && (
          <div className={classes.actions}>
            {showFreezeButton && (
              <Button
                className={classes.freezeButton}
                variant="outlined"
                disableElevation
                startIcon={<AcUnit />}
                size="large"
                onClick={handleCreateFreeze}
                data-testid="freeze-button"
              >
                Frys
              </Button>
            )}

            {cancellableMembershipsCount > 0 && (
              <Button
                className={classes.cancelButton}
                variant="outlined"
                disableElevation
                startIcon={<CancelOutlined />}
                size="large"
                onClick={() => setCancellationModalOpen(true)}
                data-testid="cancel-button"
              >
                Si opp
              </Button>
            )}
          </div>
        )
      }
    >
      <Grid container spacing={2}>
        {membershipToType.map(({ membership, type }, i) => (
          <Fragment key={`${membership.id}-fragment`}>
            {i > 0 && renderArrow(classes)}

            <Grid item xs={12} key={membership.id}>
              <MembershipView
                isFirst={i === 0}
                membership={membership}
                membershipType={type!}
              />
            </Grid>
          </Fragment>
        ))}

        {nextType && nextMembership && (
          <>
            {renderArrow(classes)}

            <Grid item xs={12}>
              <MembershipView
                membership={nextMembership}
                membershipType={nextType}
                isNext
              />
            </Grid>
          </>
        )}
      </Grid>

      {freezeInModal && membershipInFreezeModal && (
        <FreezeModal
          freeze={freezeInModal!}
          membership={membershipInFreezeModal!}
          onClose={() => setFreezeInModal(undefined)}
        />
      )}

      {isCancellationModalOpen && (
        <CancellationModal
          open={isCancellationModalOpen}
          onClose={() => setCancellationModalOpen(false)}
          memberships={activeAndUpcomingMemberships ?? []}
          membershipTypes={membershipTypes ?? []}
        />
      )}
    </Scene>
  );
};

export default Memberships;
