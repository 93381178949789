import http from "./http";

export const getPostalPlaceByPostalCode = (
  postalCode: string
): Promise<string> => {
  const url = `/util/postalCode/NO/${postalCode}`;
  return http
    .get(url)
    .then(({ data }: { data: { city: string } }) => data.city);
};
