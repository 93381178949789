import React, { FC, useCallback, useState } from "react";
import * as yup from "yup";
import {
  Stepper,
  Step as StepperStep,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  darken,
  CircularProgress,
  StepLabel,
} from "@mui/material";
import { ArrowBack, ArrowForward, Close, Save } from "@mui/icons-material";

import {
  createPartialSchema,
  date,
  Membership,
  MembershipType,
} from "@tnt/common";
import { makeStyles } from "theme";
import CloseableDialog from "components/CloseableDialog";
import DateStep from "./components/DateStep";
import ReasonStep from "./components/ReasonStep";
import FeedbackStep from "./components/FeedbackStep";
import { useSnackbar } from "notistack";
import useMembershipsOfMember from "hooks/useMembershipsOfMember";
import { cancelMembership } from "services/memberships";
import { useForm, FormProvider } from "react-hook-form";
import {MembershipCancellationDTO} from "model/MembershipCancellationDTO";

const useStyles = makeStyles()(({ spacing }) => ({
  title: {
    textAlign: "center",
  },
  stepper: {
    borderBottom: "2px dashed #bbbbbb",
    paddingBottom: spacing(2.5),
    marginTop: spacing(2.5),
    marginBottom: spacing(2.5),
    marginLeft: spacing(-2.5),
    marginRight: spacing(-2.5),
  },
  info: {
    fontSize: "1rem",
    textAlign: "center",
    marginBottom: spacing(1),
  },
  actions: {
    justifyContent: "center",
    padding: `${spacing(2)} 0`,
  },
  cancelButton: {
    marginRight: spacing(1),
    color: "white",
    backgroundColor: "#cc5050",

    "&:hover": {
      backgroundColor: darken("#cc5050", 0.2),
    },
  },
  saveButton: {
    marginLeft: spacing(1),
    backgroundColor: "#50aa50",
    color: "white",

    "&:hover": {
      backgroundColor: darken("#50aa50", 0.2),
    },
  },
}));

export enum Step {
  DATE = "date",
  REASON = "reason",
  FEEDBACK = "feedback",
}

export const DEFAULT_REASON = "none";

const validationSchema = yup
  .object()
  .shape({
    date: date(),
    reason: yup.string().notOneOf([DEFAULT_REASON], "Årsak er påkrevd."),
    reasonDescription: yup.string(),
    feedback: yup.string(),
  })
  .defined();

const stepDateValidationSchema = createPartialSchema(validationSchema, "date");
const stepReasonValidationSchema = createPartialSchema(
  validationSchema,
  "date",
  "reason",
  "reasonDescription"
);

const getValidationSchemaForStep = (step: Step) => {
  switch (step) {
    case Step.DATE:
      return stepDateValidationSchema;
    case Step.REASON:
      return stepReasonValidationSchema;
    case Step.FEEDBACK:
      return validationSchema;
  }
};

interface IProps {
  open: boolean;
  onClose: () => void;
  memberships: Membership[];
  membershipTypes: MembershipType[];
}

const CancellationModal: FC<IProps> = ({
  open,
  onClose,
  memberships,
  membershipTypes,
}) => {
  const { classes } = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [, mutate] = useMembershipsOfMember();

  const [step, setStep] = useState(Step.DATE);
  const [isSaving, setIsSaving] = useState(false);

  const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
      async (data: any) => {
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [validationSchema]
    );

  const form = useForm<{
    date: string | null;
    reason: string;
    reasonDescription: string;
    feedback: string;
  }>({
    mode: "onBlur",
    defaultValues: {
      date: "",
      reason: DEFAULT_REASON,
      reasonDescription: "",
      feedback: "",
    },
    resolver: useYupValidationResolver(getValidationSchemaForStep(step)),
  });
  const { getValues, trigger: validateForm } = form;

  const handleSave = async () => {
    if (await validateForm()) {
      setIsSaving(true);

      cancelMembership(
        memberships[memberships.length - 1],
        getValues("date") as string,
        new MembershipCancellationDTO(getValues())
      )
        .then(async () => {
          await mutate();
          enqueueSnackbar("Oppsigelsen ble registrert.", {
            variant: "success",
          });
        })
        .catch(() =>
          enqueueSnackbar("En feil oppstod ved registrering av oppsigelsen.", {
            variant: "error",
          })
        )
        .finally(() => {
          setIsSaving(false);
          onClose();
        });
    }
  };

  return (
    <CloseableDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle className={classes.title}>Registrer oppsigelse</DialogTitle>

      <DialogContent>
        <Stepper
          className={classes.stepper}
          activeStep={Object.keys(Step).indexOf(step.toUpperCase())}
          alternativeLabel
        >
          <StepperStep key={Step.DATE}>
            <StepLabel />
          </StepperStep>

          <StepperStep key={Step.REASON}>
            <StepLabel />
          </StepperStep>

          <StepperStep key={Step.FEEDBACK}>
            <StepLabel />
          </StepperStep>
        </Stepper>

        <div>
          <FormProvider {...form}>
            {step === Step.DATE && (
              <DateStep
                memberships={memberships}
                membershipTypes={membershipTypes}
              />
            )}
            {step === Step.REASON && <ReasonStep />}
            {step === Step.FEEDBACK && <FeedbackStep />}
          </FormProvider>
        </div>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          className={classes.cancelButton}
          variant="outlined"
          disableElevation
          startIcon={step === Step.DATE ? <Close /> : <ArrowBack />}
          size="large"
          onClick={() => {
            switch (step) {
              case Step.DATE:
                onClose();
                break;

              case Step.REASON:
                setStep(Step.DATE);
                break;

              case Step.FEEDBACK:
                setStep(Step.REASON);
                break;
            }
          }}
          data-testid="cancel-button"
        >
          {step === Step.DATE ? "Avbryt" : "Forrige"}
        </Button>

        <Button
          className={classes.saveButton}
          variant="outlined"
          disableElevation
          disabled={isSaving}
          startIcon={
            step !== Step.FEEDBACK ? (
              <ArrowForward />
            ) : isSaving ? (
              <CircularProgress size={20} />
            ) : (
              <Save />
            )
          }
          size="large"
          onClick={async () => {
            if (!(await validateForm())) return;

            switch (step) {
              case Step.DATE:
                setStep(Step.REASON);
                break;

              case Step.REASON:
                setStep(Step.FEEDBACK);
                break;

              case Step.FEEDBACK:
                handleSave();
                break;
            }
          }}
          data-testid="next-button"
        >
          {step === Step.FEEDBACK ? "OK" : "Neste"}
        </Button>
      </DialogActions>
    </CloseableDialog>
  );
};

export default CancellationModal;
