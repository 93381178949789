import moment, { Moment } from "moment";

import {
  DATE_ISO_FORMAT,
  DurationStart,
  Membership,
  MembershipFreeze,
  MembershipType,
} from "@tnt/common";

export const getEarliestCancellationDate = (
  membership: Membership,
  membershipType: MembershipType
) => {
  if (!membershipType.memberCancellable) return undefined;

  let earliestEnd: Moment;

  // When an end date has already been set, via Manager for instance, use that date.
  if (membership.end !== null) earliestEnd = moment(membership.end);
  // Otherwise, derive it from the membership and type.
  else if (!membershipType.duration) {
    const billedFees = membership.purchases
      .filter((p) => !!p.membershipEnd && p.billed)
      .sort((a, b) =>
        moment(a.membershipEnd).isBefore(b.membershipEnd) ? 1 : -1
      );
    earliestEnd =
      billedFees.length > 0
        ? moment(billedFees[0].membershipEnd)
        : moment(membership.start).endOf("month");
  } else {
    let withoutFreezes: Moment;

    switch (membershipType.durationStart) {
      case DurationStart.START:
        withoutFreezes = moment(membership.start).add(
          moment.duration(membershipType.duration)
        );
        break;

      case DurationStart.FIRST_1ST: {
        if (moment(membership.start).date() === 1)
          withoutFreezes = moment(membership.start).add(
            moment.duration(membershipType.duration)
          );
        else
          withoutFreezes = moment(membership.start)
            .add(1, "month")
            .startOf("month")
            .add(moment.duration(membershipType.duration));
      }
    }

    const freezePeriod = membership.freezes.reduce(
      (p, f) => p.add(moment.duration(moment(f.end).diff(f.start))),
      moment.duration(0, "seconds")
    );
    earliestEnd = withoutFreezes!.add(freezePeriod);
  }

  // Account for the cancellation period
  const cancellationPeriodEnd = moment().add(1, "month").endOf("month");
  return (
    cancellationPeriodEnd.isAfter(earliestEnd)
      ? cancellationPeriodEnd
      : earliestEnd
  )
    .endOf("month")
    .format(DATE_ISO_FORMAT);
};

export const calculateEffectiveMonthCountOfMembershipFreeze = (
  freeze: MembershipFreeze
) => {
  const start = moment(freeze.start);
  const end = moment(freeze.end);

  if (start.year() === end.year() && start.month() === end.month()) return 1;

  const monthsToDays = Array.from(freeze.getDateRange().by("days")).reduce(
    (map, d) => {
      const yearMonth = `${d.year()}${d.month()}`;
      if (!Array.from(map.keys()).includes(yearMonth))
        map.set(yearMonth, d.daysInMonth());
      return map;
    },
    new Map<string, number>()
  );

  const days = Array.from(monthsToDays.values());
  days.pop();
  const daySum = days.reduce((sum, ds) => sum + ds, 0);

  if (freeze.getDayCount() <= daySum) return days.length;
  else return days.length + 1;
};
