import { createTheme, lighten, ThemeOptions } from "@mui/material";

import { mergeThemes, RecursivePartial } from "./index";
import base from "./base";

const theme = () => {
  const black = "rgb(34, 34, 34)";
  const green = "rgb(112, 171, 65)";
  const pink = "rgb(178, 0, 97)";

  const t: RecursivePartial<ThemeOptions> = {
    tenantId: 2,

    palette: {
      primary: {
        main: black,
      },
      secondary: {
        main: green,
      },
      ternary: {
        main: pink,
      },
      background: {
        default: lighten(black, 0.1),
      },
    },

    mixins: {
      scrollbar: (width = "10px") => ({
        "&::-webkit-scrollbar": {
          width,
          backgroundColor: "#f5f5f5",
        },

        "&::-webkit-scrollbar-track": {
          WebkitBoxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.1)",
          backgroundColor: lighten(pink, 0.8),
        },

        "&::-webkit-scrollbar-thumb": {
          backgroundColor: lighten(pink, 0.2),
          borderRadius: 0,

          "&:hover": {
            backgroundColor: pink,
          },
        },
      }),
    },

    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "white",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: lighten(black, 0.25),
            color: "white",

            "& fieldset": {
              borderColor: green,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: lighten(green, 0.5),
            },

            "&.Mui-focused  .MuiOutlinedInput-notchedOutline": {
              borderColor: lighten(green, 0.5),
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: "white",

            "&.Mui-focused": {
              color: "white",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: green,
            "&.Mui-checked": {
              color: green,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: "white",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            border: "1px solid #999999",
            color: "white",
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: black,
            borderBottom: `1px solid #444444`,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            backgroundColor: lighten(black, 0.05),
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            backgroundColor: black,
            borderTop: `1px solid #444444`,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            backgroundColor: lighten(black, 0.25),
            color: "white",
          },
        },
      },
    },
  };

  return createTheme(mergeThemes(base, t));
};

export default theme;
