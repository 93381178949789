import http from "./http";

import { AuthenticationToken, Member } from "@tnt/common";

export const getAuthenticationTokensOfMember = (
  member: Member
): Promise<AuthenticationToken[]> =>
  http
    .get(`/mypage/members/${member.id}/authentication-tokens`)
    .then(({ data }) =>
      data.map((at: AuthenticationToken) => new AuthenticationToken(at))
    );

export const activateRfid = (
  member: Member,
  rfidValue: string
): Promise<void> =>
  http.post(
    `/mypage/members/${member.id}/authentication-tokens/activate-rfid?rfidValue=${rfidValue}`
  );

export const requestRfid = (member: Member): Promise<void> =>
  http.post(`/mypage/members/${member.id}/authentication-tokens/request-rfid`);
