import { useEffect, useState } from "react";
import useSWR from "swr";

import http from "services/http";

const TEN_MINUTES = 10 * 60_000;

const useVersions = (): [string | undefined, string | undefined] => {
  const [loadedVersion, setLoadedVersion] = useState<string>();
  const [latestVersion, setLatestVersion] = useState<string>();

  const fetchedVersion = useSWR(
    "/latestVersion",
    () =>
      http.get(
        `${window.location.protocol}//${window.location.host}/version.txt`
      ),
    {
      refreshInterval: TEN_MINUTES,
    }
  ).data?.data;

  useEffect(() => {
    if (fetchedVersion) {
      if (!loadedVersion) setLoadedVersion(fetchedVersion);
      setLatestVersion(fetchedVersion);
    }
  }, [fetchedVersion, loadedVersion]);

  return [loadedVersion, latestVersion];
};

export default useVersions;
