import { Member } from "@tnt/common";
import http from "services/http";

export const getAllMembersOfUser = (): Promise<Array<Member>> =>
  http
    .get(`/mypage/members`)
    .then(({ data }) => data.map((d: any) => new Member(d)));

export const getMemberById = (memberId: number): Promise<Member> =>
  http.get(`/members/${memberId}/simple`).then(({ data }) => new Member(data));

export const updateMemberPersonalia = (updated: Member): Promise<Member> =>
  http
    .put(`/mypage/members/${updated.id}`, JSON.stringify(updated))
    .then(({ data }) => new Member(data));
