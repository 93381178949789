import useSWR from "swr";
import { useAuth0 } from "@auth0/auth0-react";

import { User } from "@tnt/common";
import { getUserInfo } from "services/users";

export const USER_CACHE_KEY = "/api/user";

const useUser = (): User | undefined => {
  const { user } = useAuth0();
  return useSWR(
    user ? USER_CACHE_KEY : undefined,
    () => getUserInfo(user!.sub!),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  ).data;
};

export default useUser;
