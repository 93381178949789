import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "tss-react/mui";
import {
  Button,
  CircularProgress,
  darken,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { Close, Save } from "@mui/icons-material";

import { updateUserPassword } from "services/users";
import CloseableDialog from "components/CloseableDialog";
import { useAuth0 } from "@auth0/auth0-react";
import ViewField from "components/ViewField";

const schema = yup
  .object()
  .shape({
    password: yup.string().min(8, "Passordet må bestå av minst 8 tegn."),
    repeat: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passordene er ikke identiske."),
  })
  .defined();

const useStyles = makeStyles()(({ tenantId, palette, spacing }) => ({
  dialog: {
    width: 300,
  },
  title: {
    textAlign: "center",
  },
  content: {
    paddingTop: `${spacing(2)} !important`,
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    padding: `${spacing(2)} 0`,
  },
  cancelButton: {
    marginRight: spacing(1),
    color: "white",
    backgroundColor: "#cc5050",

    "&:hover": {
      backgroundColor: darken("#cc5050", 0.2),
    },
  },
  saveButton: {
    "&:disabled": {
      backgroundColor:
        tenantId === 1 ? "#cccccc" : darken(palette.success.dark, 0.5),
    },
  },
}));

interface IProps {
  open: boolean;
  onClose: () => void;
}

const EditPasswordModal: FC<IProps> = ({ open, onClose }) => {
  const { classes } = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { user: auth0User } = useAuth0();

  const [isSaving, setIsSaving] = useState(false);

  const {
    control,
    reset,
    getValues,
    watch,
    trigger: validateForm,
  } = useForm<{ password: string; repeat: string }>({
    mode: "onBlur",
    defaultValues: { password: "", repeat: "" },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset();
  }, [reset, open]);

  const onSave = async () => {
    if (await validateForm()) {
      setIsSaving(true);

      await updateUserPassword(auth0User?.sub!, getValues("password"))
        .then(() => {
          enqueueSnackbar("Ditt passord ble oppdatert.", {
            variant: "success",
          });
          onClose();
        })
        .catch(() =>
          enqueueSnackbar("En feil oppstod under lagring av ditt passord.", {
            variant: "error",
          })
        )
        .finally(() => setIsSaving(false));
    }
  };

  const disableSaveButton =
    watch("password").length < 8 || watch("repeat").length < 8;

  return (
    <CloseableDialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle className={classes.title}>Endre passord</DialogTitle>

      <DialogContent className={classes.content}>
        <form autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="password"
                render={({ field, fieldState }) => (
                  <ViewField label="Nytt passord">
                    <TextField
                      fullWidth
                      required
                      type="password"
                      value={field.value}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      inputRef={field.ref}
                      autoComplete="nope"
                      data-testid="password-input"
                    />
                  </ViewField>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="repeat"
                render={({ field, fieldState }) => (
                  <ViewField label="Gjenta nytt passord">
                    <TextField
                      fullWidth
                      required
                      type="password"
                      value={field.value}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      inputRef={field.ref}
                      autoComplete="nope"
                      data-testid="repeat-input"
                    />
                  </ViewField>
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          className={classes.cancelButton}
          variant="outlined"
          disableElevation
          startIcon={<Close />}
          size="large"
          onClick={onClose}
          data-testid="cancel-button"
        >
          Avbryt
        </Button>

        <Button
          className={classes.saveButton}
          variant="contained"
          color="success"
          disableElevation
          startIcon={
            isSaving ? (
              <CircularProgress size={22} style={{ color: "white" }} />
            ) : (
              <Save />
            )
          }
          size="large"
          onClick={onSave}
          disabled={disableSaveButton || isSaving}
          data-testid="save-button"
        >
          Lagre
        </Button>
      </DialogActions>
    </CloseableDialog>
  );
};

export default EditPasswordModal;
