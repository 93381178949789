import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Grid } from "@mui/material";
import {
  AccountBox,
  AccountBoxOutlined,
  CardMembershipOutlined,
  Logout,
  ManageAccountsOutlined,
  MeetingRoomOutlined,
  PersonOutlined,
  SwitchAccountOutlined,
} from "@mui/icons-material";

import { breakpointsValues, makeStyles } from "theme";
import useNavigateKeepQuery from "hooks/useNavigateKeepQuery";
import useMembersOfUser from "hooks/useMembersOfUser";
import useMember from "hooks/useMember";
import useTenantId from "hooks/useTenantId";
import MenuButton from "components/MenuButton";
import Scene from "components/Scene";

const useStyles = makeStyles()(({ spacing, windowInnerWidth }) => {
  const isSmDown = windowInnerWidth <= breakpointsValues.sm;
  return {
    root: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",

      paddingTop: spacing(4),
      paddingRight: isSmDown ? spacing(4) : 0,
      paddingBottom: spacing(4),
      paddingLeft: isSmDown ? spacing(4) : 0,
      width: "100%",
      maxWidth: 576,
    },
    hr: {
      height: 2,
      backgroundColor: "lightgrey",
      marginTop: spacing(4),
      marginBottom: spacing(4),
      marginRight: isSmDown ? `-${spacing(4)}` : 0,
      marginLeft: isSmDown ? `-${spacing(4)}` : 0,
    },
    logout: {
      padding: `${spacing(2)} !important`,
      width: "auto",
    },
  };
});

const Dashboard = () => {
  const { classes } = useStyles();

  const { user, logout } = useAuth0();

  const tenantId = useTenantId();

  const navigate = useNavigate();
  const navigateKeepQuery = useNavigateKeepQuery();

  const membersOfUser = useMembersOfUser();
  const member = useMember();

  const getName = () => {
    if (!member) return "";
    const memberName = member.customer.fullName();
    if (!member.guardian || member.guardian.auth0UserId !== user?.sub)
      return memberName;
    const guardianName = member.guardian.fullName();
    return (
      <div>
        {memberName}
        <br />({guardianName})
      </div>
    );
  };

  const greenColor = tenantId === 1 ? "rgb(0,100,0,0.75)" : "rgb(75,150,75)";

  return (
    <Scene
      title={getName()}
      icon={
        member?.guardian?.auth0UserId === user?.sub ? (
          <AccountBoxOutlined fontSize="large" style={{ color: greenColor }} />
        ) : (
          <AccountBox fontSize="large" style={{ color: greenColor }} />
        )
      }
      usePadding={false}
      showClose={false}
    >
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <MenuButton
              icon={
                <PersonOutlined
                  fontSize="large"
                  style={{ color: tenantId === 1 ? greenColor : "rgb(0,75,0)" }}
                />
              }
              title="Personalia"
              onClick={() => navigateKeepQuery("personalia")}
              dataTestId="personalia-button"
            />
          </Grid>

          <Grid item xs={6}>
            <MenuButton
              icon={
                <CardMembershipOutlined
                  fontSize="large"
                  style={{ color: "#FFC107" }}
                />
              }
              title="Medlemskap"
              onClick={() => navigateKeepQuery("memberships")}
              dataTestId="membership-button"
            />
          </Grid>

          <Grid item xs={6}>
            <MenuButton
              icon={
                <MeetingRoomOutlined
                  fontSize="large"
                  style={{ color: "#3399FF" }}
                />
              }
              title="Adgang"
              onClick={() => navigateKeepQuery("access")}
              dataTestId="access-button"
            />
          </Grid>
        </Grid>

        <div>
          <div className={classes.hr} />

          <Grid container spacing={4}>
            {membersOfUser && membersOfUser?.length > 1 ? (
              <Grid item xs={6}>
                <MenuButton
                  icon={
                    <SwitchAccountOutlined
                      fontSize="large"
                      style={{
                        color: tenantId === 1 ? greenColor : "rgb(0,75,0)",
                      }}
                    />
                  }
                  title="Bytt bruker"
                  onClick={() => navigate("/")}
                  lessPadding
                  dataTestId="change-user-button"
                />
              </Grid>
            ) : (
              <Grid item xs={6}>
                <MenuButton
                  icon={
                    <ManageAccountsOutlined
                      fontSize="large"
                      style={{ color: "#666666" }}
                    />
                  }
                  title="Innstillinger"
                  onClick={() => navigate("/settings")}
                  lessPadding
                />
              </Grid>
            )}

            <Grid item xs={6}>
              <MenuButton
                icon={<Logout fontSize="large" style={{ color: "#CC4040" }} />}
                title="Logg ut"
                onClick={() => logout({ returnTo: window.location.origin })}
                lessPadding
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </Scene>
  );
};

export default Dashboard;
