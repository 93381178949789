import { useMemo } from "react";
import { merge } from "lodash";
import * as CSS from "csstype";
import { useWindowInnerSize } from "powerhooks/useWindowInnerSize";
import { ThemeOptions } from "@mui/material";
import { useTheme as useMuiTheme } from "@mui/material/styles";
import { createMakeAndWithStyles } from "tss-react/compat";

import TnfTheme from "./tnf";
import SpinnvillTheme from "./spinnvill";

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export const mergeThemes = (
  a: RecursivePartial<ThemeOptions>,
  b: RecursivePartial<ThemeOptions>
): ThemeOptions => merge({}, a, b) as ThemeOptions;

declare module "@mui/material/styles/createTheme" {
  interface ThemeOptions {
    tenantId: number;
  }

  interface Theme {
    tenantId: number;
  }
}

declare module "@mui/material/styles/createMixins" {
  interface Mixins {
    flexContainer: (direction?: CSS.Property.FlexDirection) => CSS.Properties;
    flexItemFixedSize: (width?: string, height?: string) => CSS.Properties;
    flexItemStretchy: (grow?: boolean) => CSS.Properties;
    scrollbar: (width?: string) => CSS.Properties;
  }

  interface MixinsOptions {
    flexContainer: (direction?: CSS.Property.FlexDirection) => CSS.Properties;
    flexItemFixedSize: (width?: string, height?: string) => CSS.Properties;
    flexItemStretchy: (grow?: boolean) => CSS.Properties;
    scrollbar: (width?: string) => CSS.Properties;
  }
}

declare module "@mui/material/styles/createPalette" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Palette {
    ternary: Palette["primary"];
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PaletteOptions {
    ternary: PaletteOptions["primary"];
  }
}

export const getThemeForTenantId = (tenantId: number) => {
  switch (tenantId) {
    case 2:
    case 3:
      return SpinnvillTheme;
    default:
      return TnfTheme;
  }
};

function useTheme() {
  const { windowInnerWidth } = useWindowInnerSize();
  const muiTheme = useMuiTheme();

  return useMemo(
    () => ({
      ...muiTheme,
      windowInnerWidth,
    }),
    [windowInnerWidth, muiTheme]
  );
}

export const breakpointsValues = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
} as const;

export const { makeStyles, withStyles } = createMakeAndWithStyles({ useTheme });
