import React, { FC, useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AddCardOutlined } from "@mui/icons-material";

import CloseableDialog from "components/CloseableDialog";
import { requestRfid } from "services/authenticationTokens";
import useMember from "hooks/useMember";
import { useSnackbar } from "notistack";
import useAuthenticationTokens from "hooks/useAuthenticationTokens";

const useStyles = makeStyles()(({ spacing }) => ({
  title: {
    textAlign: "center",
  },
  content: {
    paddingTop: `${spacing(2)} !important`,
  },
  info: {
    fontSize: "1rem",
    textAlign: "center",
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    padding: `${spacing(2)} 0`,
  },
}));

interface IProps {
  open: boolean;
  onClose: () => void;
}

const OrderNewCardModal: FC<IProps> = ({ open, onClose }) => {
  const { classes } = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const member = useMember();
  const [, mutateAuthenticationTokens] = useAuthenticationTokens();

  const [isRequesting, setIsRequesting] = useState(false);

  const onRequest = async () => {
    setIsRequesting(true);

    await requestRfid(member!!)
      .then(() => {
        // Reload the authentication tokens
        mutateAuthenticationTokens();

        enqueueSnackbar("Nytt adgangskort ble bestilt.", {
          variant: "success",
        });
        onClose();
      })
      .catch(() =>
        enqueueSnackbar(
          "En feil oppstod ved bestilling av nytt adgangskort. Vennligst forsøk på nytt senere.",
          { variant: "error" }
        )
      )
      .finally(() => setIsRequesting(false));
  };

  return (
    <CloseableDialog
      open={open}
      onClose={() => (isRequesting ? {} : onClose())}
    >
      <DialogTitle className={classes.title}>Nytt adgangskort</DialogTitle>

      <DialogContent className={classes.content}>
        <div className={classes.info}>
          Trenger du nytt adgangskort?
          <br />
          Trykk på knappen for å bestille nytt.
          <br />
          <br />
          Ditt nåværende adgangskort blir deaktivert, og du mottar en kode du
          kan benytte for midlertidig adgang. Nytt kort henter du i resepsjonen.
          <br />
          <br />
          Kr 149,- blir lagt til på din neste faktura.
        </div>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          disableElevation
          color="success"
          startIcon={
            isRequesting ? (
              <CircularProgress size={22} style={{ color: "white" }} />
            ) : (
              <AddCardOutlined />
            )
          }
          size="large"
          onClick={onRequest}
          disabled={isRequesting}
          data-testid="request-button"
        >
          Bestill
        </Button>
      </DialogActions>
    </CloseableDialog>
  );
};

export default OrderNewCardModal;
