import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";

const formatWithThousandSeparator = (num: number) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

const useStyles = makeStyles()(() => ({
  root: {
    display: "inline-flex",
    alignItems: "center",
  },
  prefix: {
    marginRight: 6,
    marginTop: 1,
  },
  postfix: {
    marginLeft: 6,
    marginTop: 1,
  },
}));

interface IPriceViewProps {
  price: number;
  prefix: string;
  postfix?: string;
}

const PriceView: FC<IPriceViewProps> = ({ price, prefix, postfix }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <span className={classes.prefix}>{prefix}</span>
      <span>{formatWithThousandSeparator(price)},-</span>
      {postfix && <span className={classes.postfix}>{postfix}</span>}
    </div>
  );
};

export default PriceView;
