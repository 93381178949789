import React, { useState } from "react";
import { Grid, Stack } from "@mui/material";
import { ManageAccountsOutlined } from "@mui/icons-material";

import { makeStyles } from "theme";
import useUser from "hooks/useUser";
import Scene from "components/Scene";
import ViewField from "components/ViewField";
import EditEmailModal from "./components/EditEmailModal";
import EditPasswordModal from "./components/EditPasswordModal";
import EditButton from "components/EditButton";

const useStyles = makeStyles()(({ tenantId, palette }) => ({
  sectionTitle: {
    width: "100%",
    textAlign: "center",
    fontFamily: "arial",
    fontSize: "1.4rem",
    color: tenantId === 1 ? "black" : "white",
  },
  value: {
    color: tenantId === 1 ? "black" : palette.secondary.main,
  },
}));

const Settings = () => {
  const { classes } = useStyles();

  const user = useUser();

  const [isEditEmailModalOpen, setEditEmailModalOpen] = useState(false);
  const [isEditPasswordModalOpen, setEditPasswordModalOpen] = useState(false);

  return (
    <Scene
      title="Innstillinger"
      icon={
        <ManageAccountsOutlined fontSize="large" style={{ color: "#767676" }} />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.sectionTitle}>
          Innloggingsinfo
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <ViewField label="E-post">
              <div className={classes.value}>{user?.email}</div>
            </ViewField>

            <EditButton
              onClick={() => setEditEmailModalOpen(true)}
              dataTestId="edit-email-button"
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <ViewField label="Passord">
              <div className={classes.value}>**********</div>
            </ViewField>

            <EditButton
              onClick={() => setEditPasswordModalOpen(true)}
              dataTestId="edit-password-button"
            />
          </Stack>
        </Grid>
      </Grid>

      <EditEmailModal
        open={isEditEmailModalOpen}
        onClose={() => setEditEmailModalOpen(false)}
      />

      <EditPasswordModal
        open={isEditPasswordModalOpen}
        onClose={() => setEditPasswordModalOpen(false)}
      />
    </Scene>
  );
};

export default Settings;
