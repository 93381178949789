import useSWR from "swr";

import { AuthenticationToken } from "@tnt/common";
import { getAuthenticationTokensOfMember } from "services/authenticationTokens";
import useMember from "hooks/useMember";

const ONE_DAY = 24 * 60 * 60 * 1000;

const useAuthenticationTokens = (): [
  AuthenticationToken[] | undefined,
  () => void
] => {
  const member = useMember();
  const swr = useSWR(
    member ? `/api/members/${member.id}/authentication-tokens` : null,
    () => getAuthenticationTokensOfMember(member!),
    {
      revalidateOnMount: true,
      refreshInterval: ONE_DAY,
      dedupingInterval: ONE_DAY,
    }
  );
  return [swr.data, swr.mutate];
};

export default useAuthenticationTokens;
