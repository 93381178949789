import React, { FC } from "react";
import { darken, IconButton, lighten } from "@mui/material";
import { Edit } from "@mui/icons-material";

import { makeStyles } from "theme";

const useStyles = makeStyles()(({ tenantId, palette }) => ({
  root: {
    maxHeight: 30,
    backgroundColor:
      tenantId === 1 ? lighten(palette.primary.main, 0.98) : "white",
    marginLeft: 8,
    padding: 2,
    border: `1px solid ${lighten(palette.primary.main, 0.6)}`,

    "&:hover": {
      backgroundColor:
        tenantId === 1
          ? lighten(palette.primary.main, 0.8)
          : darken("#ffffff", 0.2),
    },
  },
  icon: {
    color: tenantId === 1 ? "inherit" : "black",
  },
}));

interface IProps {
  onClick: () => void;
  dataTestId?: string;
}

const EditButton: FC<IProps> = ({ onClick, dataTestId }) => {
  const { classes } = useStyles();

  return (
    <IconButton
      size="small"
      onClick={onClick}
      className={classes.root}
      data-testid={dataTestId}
    >
      <Edit className={classes.icon} />
    </IconButton>
  );
};

export default EditButton;
