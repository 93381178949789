import axios from "axios";

import { getAccessToken } from "App";
import { tenantId } from "hooks/useTenantId";
import * as Sentry from "@sentry/react";

const options = {
  headers: { "Content-Type": "application/json" },
  baseURL: process.env.REACT_APP_SERVER_URL + "/",
};

const http = axios.create(options);

http.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      const authHeader =
        (getAccessToken && `Bearer ${await getAccessToken()}`) ||
        config.headers.authorization;
      if (authHeader) config.headers.Authorization = authHeader;

      config.headers["Cache-Control"] = "no-cache";

      delete config.headers["Cookie"];

      if (tenantId) config.headers["X-Tenant"] = tenantId.toString();
    }
    return config;
  },
  (error) => Promise.reject(error.response)
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

export default http;
