import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "tss-react/mui";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { CreditScoreOutlined } from "@mui/icons-material";

import CloseableDialog from "components/CloseableDialog";
import { activateRfid } from "services/authenticationTokens";
import useMember from "hooks/useMember";
import { useSnackbar } from "notistack";
import useAuthenticationTokens from "hooks/useAuthenticationTokens";
import ConfirmDialog from "components/ConfirmDialog";

const useStyles = makeStyles()(({ spacing }) => ({
  title: {
    textAlign: "center",
  },
  content: {
    paddingTop: `${spacing(2)} !important`,
  },
  info: {
    fontSize: "1rem",
    textAlign: "center",
    marginBottom: spacing(1),
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    padding: `${spacing(2)} 0`,
  },
}));

interface IProps {
  open: boolean;
  onClose: () => void;
}

const ActivateCardModal: FC<IProps> = ({ open, onClose }) => {
  const { classes } = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const member = useMember();
  const [, mutateAuthenticationTokens] = useAuthenticationTokens();

  const [isActivating, setActivating] = useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const {
    control,
    reset,
    getValues,
    watch,
    trigger: validateForm,
  } = useForm<{ value: string }>({ mode: "onBlur" });

  useEffect(reset, [open, reset]);

  const onActivate = async () => {
    if (await validateForm()) {
      setActivating(true);

      await activateRfid(member!!, getValues("value"))
        .then(() => {
          // Reload the authentication tokens
          mutateAuthenticationTokens();

          enqueueSnackbar("Adgangskortet ble aktivert.", {
            variant: "success",
          });
          onClose();
        })
        .catch(() =>
          enqueueSnackbar(
            "En feil oppstod ved aktivering av adgangskortet. Vennligst forsøk på nytt senere.",
            { variant: "error" }
          )
        )
        .finally(() => {
          setActivating(false);
          setConfirmDialogOpen(false);
        });
    }
  };

  const valueIsEmpty =
    watch("value") === undefined || watch("value").trim() === "";

  return (
    <CloseableDialog open={open} onClose={onClose}>
      <DialogTitle className={classes.title}>Aktiver adgangskort</DialogTitle>

      <DialogContent className={classes.content}>
        <div className={classes.info}>Tast inn nummeret på adgangskortet:</div>

        <form autoComplete="off">
          <Controller
            control={control}
            name="value"
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                required
                type="number"
                value={field.value}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onChange={field.onChange}
                onBlur={field.onBlur}
                inputRef={field.ref}
                autoComplete="nope"
                data-testid="value-input"
              />
            )}
            rules={{
              required: true,
              pattern: {
                value: /^.{4,50}$/,
                message: "Nummeret må ha mellom 4 og 50 siffer.",
              },
            }}
          />
        </form>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          disableElevation
          color="success"
          startIcon={<CreditScoreOutlined />}
          size="large"
          onClick={() => setConfirmDialogOpen(true)}
          disabled={valueIsEmpty}
          data-testid="activate-button"
        >
          Aktiver
        </Button>
      </DialogActions>

      <ConfirmDialog
        title="Bekreft nummer"
        text={
          <div style={{ textAlign: "center" }}>
            Er du sikker på at
            <div style={{ fontWeight: 600, margin: 8 }}>{watch("value")}</div>
            er korrekt nummer?
          </div>
        }
        open={isConfirmDialogOpen}
        onCancel={() => setConfirmDialogOpen(false)}
        onConfirm={onActivate}
        progress={isActivating}
      />
    </CloseableDialog>
  );
};

export default ActivateCardModal;
