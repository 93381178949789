import { createTheme, lighten, ThemeOptions } from "@mui/material";

import { mergeThemes, RecursivePartial } from "./index";
import base from "./base";

const theme = () => {
  const blue = "rgb(8, 143, 232)";

  const t: RecursivePartial<ThemeOptions> = {
    tenantId: 1,

    palette: {
      primary: {
        main: blue,
      },
      secondary: {
        main: "#cc5050",
      },
      ternary: {
        main: blue,
      },
      background: {
        default: "#fff",
      },
    },

    mixins: {
      scrollbar: (width = "6px", height = "6px") => ({
        "&::-webkit-scrollbar": {
          width,
          height,
          backgroundColor: "#f5f5f5",
        },

        "&::-webkit-scrollbar-track": {
          WebkitBoxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.1)",
          backgroundColor: lighten(blue, 0.8),
        },

        "&::-webkit-scrollbar-thumb": {
          backgroundColor: lighten(blue, 0.15),
          borderRadius: 0,

          "&:hover": {
            backgroundColor: blue,
          },
        },
      }),
    },

    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: lighten(blue, 0.98),

            "& fieldset": {
              borderColor: lighten(blue, 0.5),
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused  .MuiOutlinedInput-notchedOutline":
              {
                borderColor: lighten(blue, 0.5),
                borderWidth: 2,
              },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: blue,
            "&$checked": {
              color: blue,
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: lighten(blue, 0.97),
            borderBottom: `1px solid ${lighten(blue, 0.6)}`,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            backgroundColor: lighten(blue, 0.97),
            borderTop: `1px solid ${lighten(blue, 0.6)}`,
          },
        },
      },
    },
  };

  return createTheme(mergeThemes(base, t));
};

export default theme;
