import React, { FC, ReactNode, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { mutate } from "swr";
import { useAuth0 } from "@auth0/auth0-react";
import { Close, Edit, Lock, PersonOutlined, Save } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";

import { Member, formatPhone, Gender, memberSchema } from "@tnt/common";
import Scene from "components/Scene";
import { getPostalPlaceByPostalCode } from "services/util";
import ViewField from "components/ViewField";
import { updateMemberPersonalia } from "services/members";
import { MEMBERS_CACHE_KEY } from "hooks/useMembersOfUser";
import { makeStyles } from "theme";
import useMember from "hooks/useMember";
import useTenantId from "hooks/useTenantId";

const useStyles = makeStyles()(({ tenantId, palette }) => ({
  groupTitle: {
    width: "100%",
    textAlign: "center",
    fontFamily: "arial",
    fontSize: "1.4rem",
  },
  value: {
    color: tenantId === 1 ? "black" : palette.secondary.main,
  },
}));

const Personalia = () => {
  const { classes } = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth0();
  const member = useMember();

  const tenantId = useTenantId();

  const [isEditing, setIsEditing] = useState(false);
  const [isLoadingPlace, setIsLoadingPlace] = useState(false);

  const getTarget = () => new Member(member ?? {});

  const {
    control,
    getValues,
    watch,
    setValue,
    reset,
    trigger: validateForm,
  } = useForm<Member>({
    mode: "onBlur",
    defaultValues: getTarget(),
    // @ts-ignore
    resolver: yupResolver(memberSchema),
  });

  useEffect(() => {
    reset(getTarget());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member, reset]);

  const getPostalPlace = (postalCode: string, guardian: boolean) => {
    const field = `${guardian ? "guardian" : "customer"}.place` as keyof Member;
    if (postalCode.length === 4) {
      setIsLoadingPlace(true);
      getPostalPlaceByPostalCode(postalCode)
        .then((p) => setValue(field, p ?? ""))
        .finally(() => setIsLoadingPlace(false));
    } else setValue(field, "");
  };

  const onSave = async () => {
    if (await validateForm()) {
      await updateMemberPersonalia(
        member!
          .with("customer", {
            phone: getValues("customer.phone"),
            email: getValues("customer.email"),
            address: getValues("customer.address"),
            zip: getValues("customer.zip"),
            place: getValues("customer.place"),
          })
          .with(
            "guardian",
            member!!.guardian
              ? {
                  phone: getValues("guardian.phone"),
                  email: getValues("guardian.email"),
                  address: getValues("guardian.address"),
                  zip: getValues("guardian.zip"),
                  place: getValues("guardian.place"),
                }
              : null
          )
      )
        .then((saved) => {
          mutate(
            MEMBERS_CACHE_KEY,
            (members: Array<Member>) =>
              members.map((m) => (m.id === saved.id ? saved : m)),
            { revalidate: false }
          );
          enqueueSnackbar("Oppdaterte personalia ble lagret.", {
            variant: "success",
          });
          setIsEditing(false);
        })
        .catch(() =>
          enqueueSnackbar(
            "En feil oppstod under lagring av dine oppdaterte personalia.",
            { variant: "error" }
          )
        );
    }
  };

  const memberHasGuardian = !!member?.guardian;
  const customerIsGuardian = member?.guardian?.auth0UserId === user?.sub;

  const disableSaveButton =
    member?.customer.phone === watch("customer.phone") &&
    member?.customer.email === watch("customer.email") &&
    member?.customer.address === watch("customer.address") &&
    member?.customer.zip === watch("customer.zip") &&
    member?.customer.place === watch("customer.place") &&
    member?.guardian?.phone === watch("guardian.phone") &&
    member?.guardian?.email === watch("guardian.email") &&
    member?.guardian?.address === watch("guardian.address") &&
    member?.guardian?.zip === watch("guardian.zip") &&
    member?.guardian?.place === watch("guardian.place");

  const greenColor = tenantId === 1 ? "rgb(0,100,0,0.75)" : "rgb(75,150,75)";

  return (
    <Scene
      title="Personalia"
      icon={<PersonOutlined fontSize="large" style={{ color: greenColor }} />}
      actions={
        <Stack
          direction="row"
          justifyContent="center"
          spacing={isEditing ? 4 : 0}
          style={{ width: "100%" }}
        >
          <Button
            variant="contained"
            disableElevation
            color="error"
            style={{ display: isEditing ? "flex" : "none" }}
            startIcon={<Close />}
            size="large"
            onClick={() => setIsEditing(false)}
            data-testid="cancel-button"
          >
            Avbryt
          </Button>

          <Button
            variant="contained"
            disableElevation
            color="success"
            style={{ display: isEditing ? "flex" : "none" }}
            startIcon={<Save />}
            size="large"
            onClick={onSave}
            disabled={disableSaveButton}
            data-testid="save-button"
          >
            Lagre
          </Button>

          <Button
            variant="contained"
            disableElevation
            color="warning"
            style={{ display: isEditing ? "none" : "flex" }}
            startIcon={<Edit />}
            size="large"
            onClick={() => setIsEditing(true)}
            data-testid="edit-button"
          >
            Rediger
          </Button>
        </Stack>
      }
    >
      <form autoComplete="off">
        <Grid container spacing={2}>
          {memberHasGuardian && (
            <Grid item xs={12} className={classes.groupTitle}>
              Medlem
            </Grid>
          )}

          <Grid item xs={12}>
            <ViewField
              label={<LockableLabel locked={isEditing}>Navn</LockableLabel>}
            >
              <div className={classes.value}>
                {member?.customer?.firstName + " " + member?.customer?.lastName}
              </div>
            </ViewField>
          </Grid>

          <Grid item xs={6}>
            <ViewField
              label={<LockableLabel locked={isEditing}>Kjønn</LockableLabel>}
            >
              <div className={classes.value}>
                {member?.gender === Gender.MALE ? "Mann" : "Kvinne"}
              </div>
            </ViewField>
          </Grid>

          <Grid item xs={6}>
            <ViewField
              label={
                <LockableLabel locked={isEditing}>Fødselsdato</LockableLabel>
              }
            >
              <div className={classes.value}>
                {moment(member?.customer?.birthDate).local().format("LL")}
              </div>
            </ViewField>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Controller
              control={control}
              name="customer.phone"
              render={({ field, fieldState }) => (
                <ViewField label="Telefon">
                  {isEditing ? (
                    <TextField
                      fullWidth
                      required
                      type="tel"
                      value={field.value}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      inputRef={field.ref}
                      autoComplete="nope"
                      data-testid="phone-input"
                    />
                  ) : member?.customer?.phone ? (
                    <div className={classes.value}>
                      {formatPhone(member?.customer.phone)}
                    </div>
                  ) : (
                    ""
                  )}
                </ViewField>
              )}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <Controller
              control={control}
              name="customer.email"
              render={({ field, fieldState }) => (
                <ViewField label="E-post">
                  {isEditing ? (
                    <TextField
                      fullWidth
                      required
                      type="email"
                      value={field.value}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      inputRef={field.ref}
                      autoComplete="nope"
                      data-testid="email-input"
                    />
                  ) : (
                    <div className={classes.value}>
                      {member?.customer?.email}
                    </div>
                  )}
                </ViewField>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="customer.address"
              render={({ field, fieldState }) => (
                <ViewField label="Adresse">
                  {isEditing ? (
                    <TextField
                      fullWidth
                      required
                      value={field.value}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      inputRef={field.ref}
                      autoComplete="nope"
                      data-testid="address-input"
                    />
                  ) : (
                    <div className={classes.value}>
                      {member?.customer?.address}
                    </div>
                  )}
                </ViewField>
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              control={control}
              name="customer.zip"
              render={({ field, fieldState }) => (
                <ViewField label="Postnummer">
                  {isEditing ? (
                    <TextField
                      fullWidth
                      required
                      type="number"
                      value={field.value}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        getPostalPlace(e.target.value, false);
                      }}
                      onBlur={field.onBlur}
                      inputRef={field.ref}
                      autoComplete="nope"
                      data-testid="zip-input"
                    />
                  ) : (
                    <div className={classes.value}>{member?.customer?.zip}</div>
                  )}
                </ViewField>
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              control={control}
              name="customer.place"
              render={({ field, fieldState }) => (
                <ViewField label="Sted">
                  {isEditing ? (
                    <TextField
                      placeholder="Sted"
                      fullWidth
                      required
                      value={field.value}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      inputRef={field.ref}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              {isLoadingPlace && <CircularProgress size={30} />}
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: {
                          padding: 0,
                        },
                      }}
                      autoComplete="nope"
                      data-testid="place-input"
                    />
                  ) :
                    <div className={classes.value}>{member?.customer?.place}</div>
                  }
                </ViewField>
              )}
            />
          </Grid>

          {memberHasGuardian && (
            <>
              <Grid
                item
                xs={12}
                className={classes.groupTitle}
                style={{ marginTop: 16 }}
              >
                Verge
              </Grid>

              <Grid item xs={12}>
                <ViewField
                  label={
                    <LockableLabel locked={isEditing && customerIsGuardian}>
                      Navn
                    </LockableLabel>
                  }
                >
                  {member?.guardian?.firstName +
                    " " +
                    member?.guardian?.lastName}
                </ViewField>
              </Grid>

              <Grid item xs={12}>
                <ViewField
                  label={
                    <LockableLabel locked={isEditing && customerIsGuardian}>
                      Fødselsdato
                    </LockableLabel>
                  }
                >
                  {moment(member?.guardian?.birthDate).local().format("LL")}
                </ViewField>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Controller
                  control={control}
                  name="guardian.phone"
                  render={({ field, fieldState }) => (
                    <ViewField label="Telefon">
                      {isEditing && customerIsGuardian ? (
                        <TextField
                          fullWidth
                          required
                          type="tel"
                          value={field.value}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          inputRef={field.ref}
                          autoComplete="nope"
                          data-testid="phone-input"
                        />
                      ) : member?.guardian?.phone ? (
                        formatPhone(member?.guardian.phone)
                      ) : (
                        ""
                      )}
                    </ViewField>
                  )}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <Controller
                  control={control}
                  name="guardian.email"
                  render={({ field, fieldState }) => (
                    <ViewField label="E-post">
                      {isEditing && customerIsGuardian ? (
                        <TextField
                          fullWidth
                          required
                          type="email"
                          value={field.value}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          inputRef={field.ref}
                          autoComplete="nope"
                          data-testid="email-input"
                        />
                      ) : (
                        member?.guardian?.email
                      )}
                    </ViewField>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="guardian.address"
                  render={({ field, fieldState }) => (
                    <ViewField label="Adresse">
                      {isEditing && customerIsGuardian ? (
                        <TextField
                          fullWidth
                          required
                          value={field.value}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          inputRef={field.ref}
                          autoComplete="nope"
                          data-testid="address-input"
                        />
                      ) : (
                        member?.guardian?.address
                      )}
                    </ViewField>
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="guardian.zip"
                  render={({ field, fieldState }) => (
                    <ViewField label="Postnummer">
                      {isEditing && customerIsGuardian ? (
                        <TextField
                          fullWidth
                          required
                          type="number"
                          value={field.value}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            getPostalPlace(e.target.value, true);
                          }}
                          onBlur={field.onBlur}
                          inputRef={field.ref}
                          autoComplete="nope"
                          data-testid="zip-input"
                        />
                      ) : (
                        member?.guardian?.zip
                      )}
                    </ViewField>
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="guardian.place"
                  render={({ field, fieldState }) => (
                    <ViewField label="Sted">
                      {isEditing && customerIsGuardian ? (
                        <TextField
                          placeholder="Sted"
                          fullWidth
                          required
                          value={field.value}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          inputRef={field.ref}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  {isLoadingPlace && (
                                    <CircularProgress size={30} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                            style: {
                              padding: 0,
                            },
                          }}
                          autoComplete="nope"
                          data-testid="place-input"
                        />
                      ) : (
                        member?.guardian?.place
                      )}
                    </ViewField>
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </Scene>
  );
};

interface ILockableLabelProps {
  locked: boolean;
  children: ReactNode;
}

const LockableLabel: FC<ILockableLabelProps> = ({ locked, children }) =>
  locked ? (
    <Tooltip
      title={
        <div>
          Dette feltet er låst for direkte redigering.
          <br />
          Kontakt treningssenteret for å endre dette feltet.
        </div>
      }
    >
      <div>
        {children}
        {locked && <Lock style={{ height: 14, marginBottom: -2 }} />}
      </div>
    </Tooltip>
  ) : (
    <div>{children}</div>
  );

export default Personalia;
