import React, { FC, ReactNode } from "react";
import { Close } from "@mui/icons-material";
import { Container, lighten, Tooltip } from "@mui/material";

import { breakpointsValues, makeStyles } from "theme";
import { useWindowInnerSize } from "powerhooks/useWindowInnerSize";
import useNavigateKeepQuery from "hooks/useNavigateKeepQuery";

const useStyles = makeStyles<{ padding: number; clickableHeader: boolean }>()(
  ({ tenantId, palette, spacing, mixins }, { padding, clickableHeader }) => ({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflowY: "hidden",
      width: "100%",
    },
    header: {
      padding: spacing(2),

      backgroundColor: lighten(
        palette.primary.main,
        tenantId === 1 ? 0.95 : 0.05
      ),

      overflowY: "hidden",

      "&:hover": {
        cursor: clickableHeader ? "pointer" : "default",
      },

      "& svg": {
        display: "block",
      },
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    icon: {
      paddingRight: spacing(1),
    },
    title: {
      fontSize: "1.2rem",
      fontWeight: 500,
      userSelect: "none",
      textAlign: "center",
      color: tenantId === 1 ? "black" : "white",
    },
    close: {
      "&:hover": {
        backgroundColor: tenantId === 1 ? "#ffeeee" : "#454545",
        borderRadius: 50,
      },
    },
    content: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflowY: "hidden",

      position: "relative",

      "&:before": {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        pointerEvents: "none",
        content: "''",
        display: "block",
        boxShadow: `inset 0px 50px 8px -50px black, inset 0px -50px 10px -50px black`,
      },
    },
    contentContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
    },
    container: {
      ...mixins.scrollbar(),

      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflowY: "auto",
      padding: padding,
    },
    actions: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: spacing(2),
      backgroundColor: lighten(
        palette.primary.main,
        tenantId === 1 ? 0.95 : 0.05
      ),
    },
  })
);

interface IProps {
  title: string | ReactNode;
  icon?: ReactNode;
  showClose?: boolean;
  actions?: React.ReactNode;
  usePadding?: boolean;
  children: React.ReactNode;
}

const Scene: FC<IProps> = ({
  title,
  icon,
  showClose = true,
  actions,
  usePadding = true,
  children,
}) => {
  const { classes } = useStyles({
    padding: usePadding ? 16 : 0,
    clickableHeader: showClose,
  });

  const navigate = useNavigateKeepQuery();

  const isSmDown =
    useWindowInnerSize().windowInnerWidth <= breakpointsValues.sm;

  return (
    <div className={classes.root}>
      <div
        className={classes.header}
        onClick={showClose ? () => navigate("/") : undefined}
      >
        <Container
          className={classes.headerContainer}
          disableGutters={isSmDown}
        >
          {icon ? <div className={classes.icon}>{icon}</div> : <div />}

          <div className={classes.title}>{title}</div>

          {showClose ? (
            <Tooltip title="Gå tilbake til meny" enterDelay={500}>
              <Close className={classes.close} fontSize="large" color="error" />
            </Tooltip>
          ) : (
            <div style={{ width: 35 }} />
          )}
        </Container>
      </div>

      <div className={classes.content}>
        <div className={classes.contentContainer}>
          <Container
            className={classes.container}
            disableGutters={isSmDown}
            maxWidth="sm"
          >
            {children}
          </Container>
        </div>
      </div>

      {actions && <div className={classes.actions}>{actions}</div>}
    </div>
  );
};

export default Scene;
