import * as CSS from "csstype";
import { createTheme, ThemeOptions } from "@mui/material";

import { RecursivePartial } from "./index";

const defaultTheme = createTheme();

const theme: RecursivePartial<ThemeOptions> = {
  mixins: {
    flexContainer: (flexDirection: CSS.Property.FlexDirection = "column") => ({
      display: "flex",
      flexDirection,
    }),
    flexItemFixedSize: (width = "auto", height = "auto") => ({
      width,
      height,

      flexGrow: 0,
      flexShrink: 0,
    }),
    flexItemStretchy: (grow = true) => ({
      flexGrow: grow ? 1 : 0,
      flexShrink: 1,
      flexBasis: "auto",

      overflowY: "auto",
      overflowX: "hidden",
    }),
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.only("sm")]: {
            height: 50,
          },
        },
      },
    },
  },

  typography: {
    body1: {
      fontSize: "1rem",
    },
  },
};

export default theme;
