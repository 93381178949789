import React, { FC, ReactNode } from "react";
import { makeStyles } from "tss-react/mui";
import { Tooltip } from "@mui/material";
import { Info } from "@mui/icons-material";

const useStyles = makeStyles()(({ tenantId, palette }) => ({
  root: {
    flex: 1,
    flexBasis: "100%",

    display: "flex",
    flexDirection: "column",
  },
  label: {
    color: tenantId === 1 ? palette.primary.main : "white",
    fontWeight: 400,
    fontSize: "0.9rem",
    cursor: "default",
    userSelect: "none",
  },
  tooltip: {
    cursor: "help",
  },
  tooltipContent: {
    display: "inline-flex",
    alignItems: "center",

    "& svg": {
      fontSize: 11,
      marginLeft: 4,
    },
  },
}));

interface IProps {
  label: string | ReactNode;
  labelAlign?: "left" | "center" | "right";
  value?: string | ReactNode;
  tooltip?: string | ReactNode;
  children?: ReactNode;
}

const ViewField: FC<IProps> = ({
  label,
  labelAlign,
  value,
  tooltip,
  children,
}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.label} style={{ textAlign: labelAlign }}>
        {tooltip ? (
          <Tooltip title={tooltip} enterDelay={500} className={classes.tooltip}>
            <div className={classes.tooltipContent}>
              <span>{label}</span>
              <Info />
            </div>
          </Tooltip>
        ) : (
          <span>{label}</span>
        )}
      </div>

      {value ? <div>{value}</div> : children}
    </div>
  );
};

export default ViewField;
