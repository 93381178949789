import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import semver from "semver";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";

import { breakpointsValues, getThemeForTenantId, makeStyles } from "theme";
import useVersions from "hooks/useVersions";
import useTenantId from "hooks/useTenantId";
import Layout from "layout";

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

export var getAccessToken: ((options?: any) => Promise<string>) | undefined =
  undefined;

const useStyles = makeStyles()(({ windowInnerWidth }) => ({
  snackbar: {
    marginBottom: windowInnerWidth <= breakpointsValues.sm ? 100 : 120,
  },
}));

const App = () => {
  const tenantId = useTenantId();

  const { classes } = useStyles();

  const [loadedVersion, latestVersion] = useVersions();
  useEffect(() => {
    if (
      latestVersion &&
      loadedVersion &&
      semver.gt(latestVersion, loadedVersion)
    )
      window.location.reload();
  }, [loadedVersion, latestVersion]);

  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  if (isLoading) return null;

  if (!isAuthenticated) {
    loginWithRedirect().then();
    return null;
  } else getAccessToken = getAccessTokenSilently;

  return (
    // <React.StrictMode> There is a bug where react-router-dom + react v18 will not change routes with StrictMode enabled
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={getThemeForTenantId(tenantId!)()}>
        <SnackbarProvider
          maxSnack={1}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          autoHideDuration={3000}
          classes={{ root: classes.snackbar }}
        >
          <CssBaseline />
          {tenantId ? <Layout /> : <div>No tenant specified.</div>}
        </SnackbarProvider>
      </ThemeProvider>
    </CacheProvider>
    // </React.StrictMode>
  );
};

export default App;
