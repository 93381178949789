import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import RedirectingAuth0Provider from "RedirectingAuth0Provider";
import ErrorFallback from "ErrorFallback";
import App from "./App";

import "moment/locale/nb";

if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
  Sentry.init({
    dsn: "https://3f3e27990342459a93d9544060117903@o1249628.ingest.sentry.io/4504031861735424",
    autoSessionTracking: true,
    integrations: [new BrowserTracing()],
    release: `mypage@${process.env.REACT_APP_VERSION}`,
    environment: process.env.REACT_APP_ENVIRONMENT,
    tracesSampleRate: 1.0,
    ignoreErrors: ["Network Error"],
  });
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
    <BrowserRouter>
      <RedirectingAuth0Provider>
        <App />
      </RedirectingAuth0Provider>
    </BrowserRouter>
  </Sentry.ErrorBoundary>
);
