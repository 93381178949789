import React, { FC, ReactNode } from "react";

import { makeStyles } from "theme";
import { lighten } from "@mui/material";

const useStyles = makeStyles()(({ palette }) => ({
  root: {
    padding: "0 3px",
    color: "white",
    fontSize: "0.92rem",
    border: `1px solid ${lighten(palette.primary.main, 0.6)}`,
    borderRadius: 5,
  },
}));

interface IProps {
  color: string;
  children: ReactNode;
}

const Tag: FC<IProps> = ({ color, children }) => {
  return (
    <div
      className={useStyles().classes.root}
      style={{ backgroundColor: color }}
    >
      {children}
    </div>
  );
};

export default Tag;
