import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import { breakpointsValues, makeStyles } from "theme";
import { useWindowInnerSize } from "powerhooks/useWindowInnerSize";
import useTenantId from "hooks/useTenantId";

const useStyles = makeStyles()(({ tenantId, palette, windowInnerWidth }) => {
  const height = windowInnerWidth > breakpointsValues.sm ? 100 : 80;
  const isSmDown = windowInnerWidth < breakpointsValues.sm;
  return {
    root: {
      height: height,
      maxHeight: height,
      backgroundColor: palette.primary.main,
    },
    container: {
      height: height,
      maxHeight: height,
    },
    content: {
      display: "flex",
      justifyContent: (() => (isSmDown ? "center" : "space-between"))(),
      alignItems: "center",

      boxSizing: "border-box",
      height: height,
      maxHeight: height,

      color: palette.common.white,
    },
    logo: {
      display: "block",
      height: "100%",
      padding: (() =>
        isSmDown
          ? tenantId === 1
            ? "20px 0"
            : "10px 0"
          : tenantId === 1
          ? "28px 0"
          : "20px 0")(),

      "&:hover": {
        cursor: "pointer",
      },
    },
  };
});

const Header: FC = () => {
  const { classes } = useStyles();

  const tenantId = useTenantId();

  const navigate = useNavigate();

  const isSmDown =
    useWindowInnerSize().windowInnerWidth <= breakpointsValues.sm;

  return (
    <div className={classes.root}>
      <Container className={classes.container} disableGutters={isSmDown}>
        <div className={classes.content}>
          <img
            className={classes.logo}
            src={`${process.env.PUBLIC_URL}/tenant/${tenantId}/logo-header.svg`}
            onClick={() => navigate("")}
            alt={`Top Notch Fitness Logo`}
          />
        </div>
      </Container>
    </div>
  );
};

export default Header;
