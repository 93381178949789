import React from "react";
import { Route, Routes } from "react-router-dom";

import Dashboard from "scenes/Dashboard";
import Personalia from "scenes/Personalia";
import Memberships from "scenes/Memberships";
import Access from "scenes/Access";
import Settings from "scenes/Settings";

const MemberRoutes = () => (
  <Routes>
    <Route path="/" element={<Dashboard />} />
    <Route path="/personalia" element={<Personalia />} />
    <Route path="/memberships" element={<Memberships />} />
    <Route path="/access" element={<Access />} />
    <Route path="/settings" element={<Settings />} />
  </Routes>
);

export default MemberRoutes;
