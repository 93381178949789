import React, { FC } from "react";
import { DialogProps } from "@mui/material/Dialog/Dialog";
import { Dialog, IconButton, Tooltip } from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(({ spacing }) => ({
  closeButtonContainer: {
    position: "absolute",
    right: spacing(1),
    top: spacing(1),
    textAlign: "right",
  },
}));

type Props = {
  onClose: () => void;
} & Omit<DialogProps, "onClose">;

const CloseableDialog: FC<Props> = ({ onClose, children, ...rest }) => (
  <Dialog onClose={onClose} {...rest}>
    <div className={useStyles().classes.closeButtonContainer}>
      <Tooltip title="Lukk vindu" enterDelay={500}>
        <IconButton size="small" onClick={onClose}>
          <Close fontSize="large" color="error" />
        </IconButton>
      </Tooltip>
    </div>

    {children}
  </Dialog>
);

export default CloseableDialog;
