import React, { FC, ReactNode } from "react";

import { breakpointsValues, makeStyles } from "theme";
import { darken, lighten } from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles()(
  ({ tenantId, palette, spacing, windowInnerWidth }) => {
    const isSmDown = windowInnerWidth <= breakpointsValues.sm;
    return {
      root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        padding: spacing(isSmDown ? 2 : 4),

        border: `2px solid ${
          tenantId === 1
            ? lighten(palette.primary.main, 0.25)
            : darken(palette.secondary.main, 0.25)
        }`,
        borderRadius: 5,
        boxShadow: `3px 3px 3px ${
          tenantId === 1 ? "#999999" : palette.primary.main
        }`,

        backgroundColor:
          tenantId === 1
            ? lighten(palette.primary.main, 0.95)
            : palette.secondary.main,

        "& > div": {
          margin: spacing(0.5),
        },

        "& svg": {
          display: "block",
        },

        "&:hover": {
          cursor: "pointer",
          backgroundColor:
            tenantId === 1
              ? lighten(palette.primary.main, 0.85)
              : darken(palette.secondary.main, 0.2),
        },
      },
      title: {
        marginTop: spacing(2),
        textAlign: "center",
        fontFamily: "arial",
        fontSize: "1.05rem",
        userSelect: "none",
        whiteSpace: "nowrap",
        color: tenantId === 1 ? "black" : "white",
      },
      subtitle: {
        fontSize: "0.9rem",
        color: "grey",
      },
      lessPadding: {
        padding: spacing(2),
        width: "auto",
      },
    };
  }
);

interface IProps {
  icon: ReactNode;
  title: string;
  subtitle?: string;
  onClick: () => void;
  lessPadding?: boolean;
  dataTestId?: string;
}

const MenuButton: FC<IProps> = ({
  icon,
  title,
  subtitle,
  onClick,
  lessPadding,
  dataTestId,
}) => {
  const { classes } = useStyles();

  return (
    <div
      className={clsx(classes.root, lessPadding && classes.lessPadding)}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <div>{icon}</div>
      <div className={classes.title}>{title}</div>
      <div className={classes.subtitle}>{subtitle}</div>
    </div>
  );
};

export default MenuButton;
