import React, { FC } from "react";
import { Grid, lighten } from "@mui/material";
import { CreditCard, Pin } from "@mui/icons-material";

import { makeStyles } from "theme";
import {
  AuthenticationToken as AuthenticationTokenModel,
  AuthenticationTokenType,
} from "@tnt/common";
import moment from "moment";

const useStyles = makeStyles()(({ spacing, palette }) => ({
  root: {
    padding: spacing(2),
    backgroundColor: lighten(palette.primary.main, 0.98),
    border: `2px solid ${lighten(palette.primary.main, 0.6)}`,
    borderRadius: 5,
  },
  title: {
    fontSize: "0.9rem",
    marginBottom: spacing(1),
    textAlign: "center",
  },
  value: {
    fontSize: "1.4rem",
    fontWeight: 600,
    textAlign: "center",
  },
  validTitle: {
    fontStyle: "italic",
  },
  validUntil: {
    marginTop: spacing(1),
    fontSize: "0.85rem",
    textAlign: "center",
  },
}));

interface IProps {
  authenticationToken: AuthenticationTokenModel;
}

const AuthenticationToken: FC<IProps> = ({ authenticationToken }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={2}>
          {authenticationToken.type === AuthenticationTokenType.RFID ? (
            <CreditCard fontSize="large" />
          ) : (
            <Pin fontSize="large" />
          )}
        </Grid>

        <Grid item xs={8}>
          <div className={classes.title}>
            {authenticationToken.type === AuthenticationTokenType.RFID
              ? "Kort"
              : "Kode"}
          </div>
          <div className={classes.value}>{authenticationToken.value}</div>
          {authenticationToken.validTo !== null && (
            <div className={classes.validUntil}>
              <span className={classes.validTitle}>Gyldig</span>
              <br />
              {moment
                .utc(authenticationToken.validFrom)
                .local()
                .format("Do MMM")}{" "}
              -{" "}
              {moment.utc(authenticationToken.validTo).local().format("Do MMM")}
            </div>
          )}
        </Grid>

        <Grid item xs={2} />
      </Grid>
    </div>
  );
};

export default AuthenticationToken;
