export var tenantId: number | undefined = undefined;

const subdomainToTenantId = new Map([
  ["topnotchfitness", 1],
  ["spinnvilldrammen", 2],
  ["spinnvillnotodden", 3],
]);

const useTenantId = (): number | undefined => {
  tenantId = window.location.host
    .split(".")
    .map(
      (sd) =>
        Array.from(subdomainToTenantId).find(
          ([domain]) => domain === sd
        )?.[1]
    )
    .find((it) => !!it);

  return tenantId;
};

export default useTenantId;
