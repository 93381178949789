import useSWR from "swr";

import { Member } from "@tnt/common";
import { getAllMembersOfUser } from "services/members";

export const MEMBERS_CACHE_KEY = "/api/members";

const ONE_DAY = 24 * 60 * 60 * 1000;

const useMembersOfUser = (): Member[] | undefined =>
  useSWR(MEMBERS_CACHE_KEY, getAllMembersOfUser, {
    revalidateOnMount: true,
    refreshInterval: ONE_DAY,
    dedupingInterval: ONE_DAY,
  }).data;

export default useMembersOfUser;
