import React, { FC, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { mutate } from "swr";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "tss-react/mui";
import {
  Button,
  CircularProgress,
  darken,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Close, Save } from "@mui/icons-material";

import { updateUserEmail } from "services/users";
import useUser, { USER_CACHE_KEY } from "hooks/useUser";
import CloseableDialog from "components/CloseableDialog";

const schema = yup
  .object()
  .shape({
    email: yup.string().email("Ugyldig e-postadresse."),
  })
  .defined();

const useStyles = makeStyles()(({ tenantId, palette, spacing }) => ({
  title: {
    textAlign: "center",
  },
  content: {
    paddingTop: `${spacing(2)} !important`,
  },
  info: {
    fontSize: "1rem",
    textAlign: "center",
    marginBottom: spacing(1),
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    padding: `${spacing(2)} 0`,
  },
  cancelButton: {
    marginRight: spacing(1),
    color: "white",
    backgroundColor: "#cc5050",

    "&:hover": {
      backgroundColor: darken("#cc5050", 0.2),
    },
  },
  saveButton: {
    "&:disabled": {
      backgroundColor:
        tenantId === 1 ? "#cccccc" : darken(palette.success.dark, 0.5),
    },
  },
}));

interface IProps {
  open: boolean;
  onClose: () => void;
}

const EditEmailModal: FC<IProps> = ({ open, onClose }) => {
  const { classes } = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { user: auth0User } = useAuth0();
  const user = useUser();

  const [isSaving, setIsSaving] = useState(false);

  const {
    control,
    reset,
    getValues,
    watch,
    trigger: validateForm,
  } = useForm<{ email: string }>({
    mode: "onBlur",
    defaultValues: user,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(user);
  }, [user, reset, open]);

  const onSave = async () => {
    if (await validateForm()) {
      setIsSaving(true);

      await updateUserEmail(auth0User?.sub!, getValues("email"))
        .then((user) => {
          mutate(USER_CACHE_KEY, user, { revalidate: false });
          enqueueSnackbar("Din e-postadresse for innlogging ble oppdatert.", {
            variant: "success",
          });
          onClose();
        })
        .catch((err) =>
          enqueueSnackbar(
            err.status === "400" && err.reason === "emailCollision"
              ? "Den valgte e-postadressen er allerede i bruk."
              : "En feil oppstod under lagring av din oppdaterte e-postadresse for innlogging.",
            {
              variant: "error",
            }
          )
        )
        .finally(() => setIsSaving(false));
    }
  };

  const disableSaveButton = user?.email === watch("email");

  return (
    <CloseableDialog open={open} onClose={onClose}>
      <DialogTitle className={classes.title}>Endre e-postadresse</DialogTitle>

      <DialogContent className={classes.content}>
        <div className={classes.info}>
          Hvilken e-postadresse ønsker du å logge inn med?
        </div>

        <form autoComplete="off">
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                required
                type="email"
                value={field.value}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onChange={field.onChange}
                onBlur={field.onBlur}
                inputRef={field.ref}
                autoComplete="nope"
                data-testid="email-input"
              />
            )}
          />
        </form>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          className={classes.cancelButton}
          variant="outlined"
          disableElevation
          startIcon={<Close />}
          size="large"
          onClick={onClose}
          data-testid="cancel-button"
        >
          Avbryt
        </Button>

        <Button
          className={classes.saveButton}
          variant="contained"
          color="success"
          disableElevation
          startIcon={
            isSaving ? (
              <CircularProgress size={22} style={{ color: "white" }} />
            ) : (
              <Save />
            )
          }
          size="large"
          onClick={onSave}
          disabled={disableSaveButton || isSaving}
          data-testid="save-button"
        >
          Lagre
        </Button>
      </DialogActions>
    </CloseableDialog>
  );
};

export default EditEmailModal;
