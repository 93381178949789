import React, { FC, ReactNode } from "react";
import {
  Button,
  CircularProgress,
  darken,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Close, Done } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import CloseableDialog from "components/CloseableDialog";

const useStyles = makeStyles()(({ spacing }) => ({
  title: {
    textAlign: "center",
  },
  content: {
    paddingTop: `${spacing(2)} !important`,
  },
  actions: {
    justifyContent: "center",
    padding: spacing(2),
  },
  noButton: {
    marginRight: spacing(1),
    color: "white",
    backgroundColor: "#cc5050",

    "&:hover": {
      backgroundColor: darken("#cc5050", 0.2),
    },
  },
  yesButton: {
    marginLeft: spacing(1),
    backgroundColor: "#50aa50",
    color: "white",

    "&:hover": {
      backgroundColor: darken("#50aa50", 0.2),
    },
  },
}));

interface IProps {
  title: string;
  text: string | ReactNode;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  progress?: boolean;
  [other: string]: any;
}

const ConfirmDialog: FC<IProps> = ({
  title,
  text,
  open,
  onCancel,
  onConfirm,
  progress,
}) => {
  const { classes } = useStyles();

  return (
    <CloseableDialog
      title={title}
      open={open}
      onClose={() => (!progress ? onCancel() : undefined)}
    >
      <DialogTitle className={classes.title}>{title}</DialogTitle>

      <DialogContent className={classes.content}>{text}</DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          className={classes.noButton}
          variant="outlined"
          color="error"
          disabled={progress}
          startIcon={<Close />}
          size="large"
          onClick={onCancel}
          data-testid="no-button"
        >
          Nei
        </Button>

        <Button
          className={classes.yesButton}
          variant="outlined"
          disableElevation
          color="primary"
          disabled={progress}
          startIcon={progress ? <CircularProgress size={20} /> : <Done />}
          size="large"
          onClick={onConfirm}
          data-testid="yes-button"
        >
          Ja
        </Button>
      </DialogActions>
    </CloseableDialog>
  );
};

export default ConfirmDialog;
