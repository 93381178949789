import { User } from "@tnt/common";

import http from "services/http";
import { ApiError } from "model/ApiError";

export const getUserInfo = (id: String): Promise<User> =>
  http.get(`/users/${id}`).then((d: any) => new User(d.data));

export const updateUserEmail = (id: String, email: String): Promise<User> =>
  http
    .put(`/users/${id}/email`, JSON.stringify({ email: email }))
    .then((d: any) => new User(d.data))
    .catch((err: any) => Promise.reject(new ApiError(err.response.data)));

export const updateUserPassword = (
  id: String,
  password: String
): Promise<User> =>
  http.put(`/users/${id}/password`, JSON.stringify({ password: password }));
