export class ApiError {
  status: string;
  reason: string;
  message: string;

  constructor(o: ApiError) {
    this.status = o.status;
    this.reason = o.reason;
    this.message = o.message;
  }
}
