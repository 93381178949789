import React from "react";

const ErrorFallback = () => (
  <div style={{ padding: 16 }}>
    <h1>
      Obs! Her var det noe rusk i maskineriet. Vennligst prøv igjen senere.
    </h1>
  </div>
);

export default ErrorFallback;
