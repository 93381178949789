import useSWR from "swr";

import { getMembershipTypes } from "services/memberships";
import { MembershipType } from "@tnt/common";

const ONE_HOUR = 3_600_000;

const useMembershipTypes = (): MembershipType[] | undefined =>
  useSWR("/api/membership-types", getMembershipTypes, {
    revalidateOnFocus: false,
    refreshInterval: ONE_HOUR,
    dedupingInterval: ONE_HOUR,
  }).data;

export default useMembershipTypes;
