import React from "react";
import { Helmet } from "react-helmet";

import Header from "layout/Header";
import Footer from "layout/Footer";
import useTenantId from "hooks/useTenantId";
import UserRoutes from "UserRoutes";

const tenantIdToName = new Map([
  [1, "Top Notch Fitness"],
  [2, "Spinnvill Drammen"],
  [3, "Spinnvill Notodden"],
]);

const Layout = () => {
  const tenantId = useTenantId();

  return (
    <>
      <Helmet>
        <title>
          {tenantId ? `${tenantIdToName.get(tenantId)} - ` : ""}Min Side
        </title>

        {["16x16", "32x32"].map((dim) => (
          <link
            key={dim}
            href={`${process.env.PUBLIC_URL}/tenant/${tenantId}/favicon-${dim}.png`}
            rel="icon"
            sizes={dim}
            type="image/png"
          />
        ))}
      </Helmet>

      <Header />
      <UserRoutes />
      <Footer />
    </>
  );
};

export default Layout;
