import React from "react";
import { Route, Routes } from "react-router-dom";

import useMemberId from "hooks/useMemberId";
import Settings from "scenes/Settings";
import MemberRoutes from "MemberRoutes";
import MemberSelection from "scenes/MemberSelection";

const UserRoutes = () => {
  const [member] = useMemberId();

  return (
    <Routes>
      <Route path="/settings" element={<Settings />} />
      <Route
        path="*"
        element={member ? <MemberRoutes /> : <MemberSelection />}
      />
    </Routes>
  );
};

export default UserRoutes;
