import { useMemo } from "react";

import { Member } from "@tnt/common";
import useMemberId from "hooks/useMemberId";
import useMembersOfUser from "hooks/useMembersOfUser";

const useMember = (): Member | undefined => {
  const [memberId] = useMemberId();
  const membersOfUser = useMembersOfUser();

  return useMemo(
    () => membersOfUser?.find((m) => m.id === memberId),
    [memberId, membersOfUser]
  );
};

export default useMember;
