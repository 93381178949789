import http from "./http";

import { Membership, MembershipFreeze, MembershipType } from "@tnt/common";
import { tenantId } from "hooks/useTenantId";
import { MembershipCancellationDTO } from "model/MembershipCancellationDTO";

export const getMembershipTypes = () =>
  http
    .get(`/${tenantId}/membership-types`)
    .then(({ data }) => data.map((m: MembershipType) => new MembershipType(m)));

export const getMembershipsOfMember = (
  memberId: number
): Promise<Membership[]> =>
  http
    .get(`/mypage/members/${memberId}/memberships`)
    .then(({ data }) => data.map((m: Membership) => new Membership(m)));

export const saveMembershipFreezes = (
  freezes: MembershipFreeze[],
  membership: Membership
): Promise<MembershipFreeze[]> =>
  http
    .put(
      `/mypage/members/${membership.memberId}/memberships/${membership.id}/freezes`,
      freezes
    )
    .then(({ data }) =>
      data.map((mf: MembershipFreeze) => new MembershipFreeze(mf))
    );

export const cancelMembership = (
  membership: Membership,
  date: string,
  cancellation: MembershipCancellationDTO
): Promise<Membership> =>
  http
    .put(
      `/mypage/members/${membership.memberId}/memberships/${membership.id}/cancel?date=${date}`,
      cancellation
    )
    .then(({ data }) => new Membership(data));
