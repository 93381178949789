import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";

import { makeStyles } from "theme";

const useStyles = makeStyles()(({ spacing }) => ({
  info: {
    fontSize: "1rem",
    textAlign: "center",
    marginBottom: spacing(1),
  },
}));

const FeedbackStep = () => {
  const { classes } = useStyles();

  const { control } = useFormContext();

  return (
    <div>
      <div className={classes.info}>Har du en tilbakemelding til oss?</div>

      <Controller
        control={control}
        name="feedback"
        render={({ field }) => (
          <TextField
            fullWidth
            multiline
            rows={3}
            value={field.value}
            onChange={field.onChange}
            data-testid="feedback-input"
          />
        )}
      />
    </div>
  );
};

export default FeedbackStep;
