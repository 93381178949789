import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import * as yup from "yup";
import { cloneDeep } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useSnackbar } from "notistack";
import {
  Button,
  CircularProgress,
  darken,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Close, DeleteOutline, Save } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";

import {
  DATE_UI_FORMAT,
  DateInput,
  Membership,
  MembershipFreeze,
  membershipFreezeSchema,
  date,
} from "@tnt/common";
import ViewField from "components/ViewField";
import CloseableDialog from "components/CloseableDialog";
import { saveMembershipFreezes } from "services/memberships";
import { calculateEffectiveMonthCountOfMembershipFreeze } from "helpers/membership";
import useMembershipsOfMember from "hooks/useMembershipsOfMember";

const freezesCountSchema = (freezes: MembershipFreeze[]) => {
  let startSchema = date("start");

  startSchema = startSchema.test({
    test: (start, context) => {
      // Count the number of freezes, including this one, that starts within 12 months.
      if (!context.parent.id) {
        const freezeCount =
          freezes.filter(
            (f) =>
              moment(f.start).isAfter(moment(start).subtract(1, "year")) &&
              !moment(f.start).isAfter(start)
          ).length + 1;

        if (freezeCount > 3)
          return context.createError({
            path: "start",
            message: `Et medlemskap kan maksimalt fryses 3 ganger per 12 måneder.`,
          });
      }

      return true;
    },
  });

  return yup
    .object()
    .shape({
      start: startSchema,
    })
    .defined();
};

const freezesDurationSchema = (otherFreezes: MembershipFreeze[]) => {
  let startSchema = date("start");

  startSchema = startSchema.test({
    test: (start, context) => {
      const freezes = otherFreezes.concat(new MembershipFreeze(context.parent));

      // Count the number of effective freeze months within 12 months.
      const monthCount = freezes
        .filter(
          (f) =>
            moment(f.start).isAfter(moment(start).subtract(1, "year")) &&
            !moment(f.start).isAfter(start)
        )
        .map((f) => calculateEffectiveMonthCountOfMembershipFreeze(f))
        .reduce((sum, c) => sum + c, 0);

      if (monthCount > 3)
        return context.createError({
          path: "start",
          message: `Et medlemskap kan maksimalt fryses 3 måneder per 12 måneder.`,
        });

      return true;
    },
  });

  return yup
    .object()
    .shape({
      start: startSchema,
    })
    .defined();
};

const updatedMembershipEndCollisionSchema = (membership: Membership) => {
  let startSchema = date("start");

  if (membership.end)
    startSchema = startSchema.max(
      membership.end,
      "En frys må starte i et aktivt medlemskapet."
    );

  return yup
    .object()
    .shape({
      start: startSchema,
    })
    .defined();
};

const useStyles = makeStyles()(({ spacing }) => ({
  title: {
    textAlign: "center",
  },
  content: {
    paddingTop: `${spacing(2)} !important`,
  },
  info: {
    textAlign: "center",
    fontStyle: "italic",
  },
  actionsContainer: {
    justifyContent: "center",
    padding: `${spacing(2)} 0`,
  },
  actions: {
    display: "flex",
    justifyContent: "center",
  },
  cancelButton: {
    marginRight: spacing(1),
    color: "white",
    backgroundColor: "#cc5050",

    "&:hover": {
      backgroundColor: darken("#cc5050", 0.2),
    },
  },
  saveButton: {
    marginLeft: spacing(1),
    backgroundColor: "#50aa50",
    color: "white",

    "&:hover": {
      backgroundColor: darken("#50aa50", 0.2),
    },
  },
}));

interface IProps {
  freeze: MembershipFreeze;
  membership: Membership;
  onClose: () => void;
}

const FreezeModal: FC<IProps> = ({ freeze, membership, onClose }) => {
  const { classes } = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [, mutate] = useMembershipsOfMember();

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    control,
    getValues,
    setValue,
    reset,
    watch,
    trigger: validateForm,
  } = useForm<MembershipFreeze>({
    mode: "onBlur",
    defaultValues: cloneDeep(freeze),
    // @ts-ignore
    resolver: (function () {
      const otherFreezes = membership.freezes.filter((f) => f.id !== freeze.id);
      return yupResolver(
        membershipFreezeSchema(
          membership,
          otherFreezes,
          freeze.id ? freeze : undefined,
          { isEndAfterMembershipEndValid: true }
        )
          .concat(freezesCountSchema(membership.freezes))
          .concat(freezesDurationSchema(otherFreezes))
          .concat(updatedMembershipEndCollisionSchema(membership))
      );
    })(),
  });

  useEffect(() => {
    if (freeze) reset(cloneDeep(freeze));
  }, [freeze, reset]);

  const handleSave = async () => {
    if (
      freeze.id &&
      freeze.start === getValues("start") &&
      freeze.end === getValues("end")
    ) {
      onClose();
      return;
    }

    if (await validateForm()) {
      // Modify a copy of the freezes. That way, in case something fails, no reverting is necessary.
      let freezesCopy = cloneDeep(membership.freezes);

      const editedFreeze = new MembershipFreeze({ ...getValues() });

      // Concatenate the freeze with another freeze when they are "touching" eachother
      const neighbourFreeze = freezesCopy.find(
        (f) =>
          moment(editedFreeze.start).subtract(1, "day").isSame(f.end, "days") ||
          moment(editedFreeze.end).add(1, "day").isSame(f.start, "days")
      );

      if (neighbourFreeze) {
        neighbourFreeze.start = moment(neighbourFreeze.start).isBefore(
          editedFreeze.start
        )
          ? neighbourFreeze.start
          : editedFreeze.start;
        neighbourFreeze.end = moment(neighbourFreeze.end).isAfter(
          editedFreeze.end
        )
          ? neighbourFreeze.end
          : editedFreeze.end;

        setIsSaving(true);
        persistFreezes(
          freeze.id
            ? freezesCopy.filter((f) => f.id !== freeze.id)
            : freezesCopy,
          false
        );
      } else {
        const updatedFreezes = freeze!.id
          ? membership.freezes.map((f) =>
              f.id === freeze!.id ? editedFreeze : f
            )
          : membership.freezes.concat([editedFreeze]);

        setIsSaving(true);
        persistFreezes(updatedFreezes, false);
      }
    }
  };

  const handleDelete = () => {
    const updatedFreezes = membership.freezes.filter((f) => f.id !== freeze.id);
    setIsDeleting(true);
    persistFreezes(updatedFreezes, true);
  };

  const persistFreezes = (
    updatedFreezes: MembershipFreeze[],
    isDelete: Boolean
  ) => {
    saveMembershipFreezes(updatedFreezes, membership)
      .then(async () => {
        await mutate();
        enqueueSnackbar(
          `Fryseperioden ble ${
            isDelete ? "slettet" : freeze.id ? "oppdatert" : "opprettet"
          }.`,
          {
            variant: "success",
          }
        );

        onClose();
      })
      .catch(() =>
        enqueueSnackbar(
          "En feil oppstod ved lagring av medlemskapets fryseperioder.",
          {
            variant: "error",
          }
        )
      )
      .finally(() => {
        setIsSaving(false);
        setIsDeleting(false);
      });
  };

  const getReactivateDateText = () => {
    const reactivateDate = moment(watch("end")).add(1, "day");
    if (reactivateDate.isSame(moment().startOf("day"))) {
      return "Medlemskapet blir aktivt igjen i dag.";
    } else {
      return `Medlemskapet blir aktivt igjen ${moment(watch("end"))
        .add(1, "day")
        .format(DATE_UI_FORMAT)}.`;
    }
  };

  const getMonthCountUsageText = () => {
    const count = calculateEffectiveMonthCountOfMembershipFreeze(
      new MembershipFreeze(getValues())
    );
    return `Denne fryseperioden bruker ${count} frysemåned${
      count > 1 ? "er" : ""
    }.`;
  };

  const isDeletable = freeze.id && moment(freeze.start).isSameOrAfter(moment());

  return (
    <CloseableDialog open={!!freeze} onClose={onClose}>
      <DialogTitle className={classes.title}>
        {freeze.id ? "Endre" : "Registrer"} fryseperiode
      </DialogTitle>

      <DialogContent className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ViewField label="Fra og med">
              <Controller
                control={control}
                name="start"
                render={({ field, fieldState }) => (
                  <DateInput
                    fullWidth
                    required
                    disabled={!moment(freeze?.start).isAfter(moment())}
                    value={field.value}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(val) => {
                      field.onChange(val);
                      if (val && moment(val).isAfter(getValues("end")))
                        setValue("end", val);
                    }}
                    onBlur={field.onBlur}
                    inputRef={field.ref}
                    autoComplete="nope"
                    data-testid="start-input"
                  />
                )}
              />
            </ViewField>
          </Grid>

          <Grid item xs={12} md={6}>
            <ViewField label="Til og med">
              <Controller
                control={control}
                name="end"
                render={({ field, fieldState }) => (
                  <DateInput
                    fullWidth
                    required
                    value={field.value}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    inputRef={field.ref}
                    autoComplete="nope"
                    data-testid="end-input"
                  />
                )}
              />
            </ViewField>
          </Grid>

          <Grid className={classes.info} item xs={12}>
            <div>{getReactivateDateText()}</div>
            <div style={{ marginTop: 16 }}>{getMonthCountUsageText()}</div>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.actionsContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.actions}>
              <Button
                className={classes.cancelButton}
                variant="outlined"
                disableElevation
                startIcon={<Close />}
                size="large"
                onClick={onClose}
                data-testid="cancel-button"
              >
                Avbryt
              </Button>

              <Button
                className={classes.saveButton}
                color="success"
                variant="contained"
                disableElevation
                startIcon={
                  isSaving ? (
                    <CircularProgress size={22} style={{ color: "white" }} />
                  ) : (
                    <Save />
                  )
                }
                size="large"
                onClick={handleSave}
                disabled={isSaving || isDeleting}
                data-testid="save-button"
              >
                Lagre
              </Button>
            </div>
          </Grid>

          {isDeletable && (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                className={classes.cancelButton}
                variant="outlined"
                disableElevation
                startIcon={
                  isDeleting ? (
                    <CircularProgress size={22} style={{ color: "white" }} />
                  ) : (
                    <DeleteOutline />
                  )
                }
                size="large"
                onClick={handleDelete}
                disabled={isSaving || isDeleting}
                data-testid="delete-button"
              >
                Slett
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </CloseableDialog>
  );
};

export default FreezeModal;
