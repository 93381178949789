import React from "react";
import { Container } from "@mui/material";

import { breakpointsValues, makeStyles } from "theme";
import useTenantId from "hooks/useTenantId";

const useStyles = makeStyles()(({ tenantId, palette, windowInnerWidth }) => {
  const height = windowInnerWidth > breakpointsValues.sm ? 40 : 30;
  const isSmDown = windowInnerWidth < breakpointsValues.sm;
  return {
    root: {
      height: height,
      maxHeight: height,
      backgroundColor: palette.primary.main,
    },
    container: {
      height: height,
      maxHeight: height,
    },
    content: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      boxSizing: "border-box",
      height: height,
      maxHeight: height,
      padding: (() =>
        isSmDown
          ? tenantId === 1
            ? "9px 0"
            : "5px 0"
          : tenantId === 1
          ? "13px 0"
          : "7px 0")(),

      color: palette.common.white,
    },
    version: {
      fontSize: "0.6rem",
    },
    logo: {
      display: "block",
      maxHeight: "100%",
    },
  };
});

const Footer = () => {
  const { classes } = useStyles();

  const tenantId = useTenantId();

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.content}>
          <div className={classes.version}>
            Socius MyPage v{process.env.REACT_APP_VERSION}
          </div>

          <img
            className={classes.logo}
            src={`${process.env.PUBLIC_URL}/tenant/${tenantId}/logo-footer.svg`}
            alt={`Top Notch Fitness Logo`}
          />
        </div>
      </Container>
    </div>
  );
};

export default Footer;
