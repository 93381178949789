import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import useMembersOfUser from "hooks/useMembersOfUser";

const PARAM_NAME = "id";

const useMemberId = (): [number | undefined, (memberId: number) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const membersOfUser = useMembersOfUser();

  const [memberId, setMemberId] = useState<number | undefined>();

  const queryParamId = useMemo(
    () => Number(searchParams.get(PARAM_NAME)),
    [searchParams]
  );

  const setMemberIdHandler = useCallback(
    (memberId: number) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(PARAM_NAME, `${memberId}`);
      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    if (!membersOfUser) return;
    if (queryParamId && !membersOfUser.find((m) => m.id === queryParamId))
      navigate("/");
    else setMemberId(queryParamId);
  }, [membersOfUser, navigate, queryParamId]);

  return [memberId, setMemberIdHandler];
};

export default useMemberId;
