export class MembershipCancellationDTO {
  reason: string;
  reasonDescription: string;
  feedback: string;

  constructor(o: MembershipCancellationDTO) {
    this.reason = o.reason;
    this.reasonDescription = o.reasonDescription;
    this.feedback = o.feedback;
  }
}
